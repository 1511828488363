import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

export type SortDirection = 'asc' | 'desc' | '';

export interface TableDataDTO {
  [key: string]: string
}

export interface TableColumns {
  colName: string;
  sortable?: boolean;
  colId: SortColumn;
  link?: boolean;
  icon?: string;
  align?: string;
  elem?: string;
  width?: string;
  uppercase?: boolean;
  color?: string;
  weight?: string;
  defaultSort?: string;
  boolicon?: boolean;
  tooltip?: string;
  checkable?: boolean;
  hidden?: boolean;
  display?: string;
}

export interface TableValue {
  value: string;
  anchor?: string;
}

export type SortColumn = keyof TableDataDTO | '';

export const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export type ColumnNames = keyof TableColumns;

export const SP_FIELDS_LOGS_TABLE_HEADINGS: TableColumns[] = [
  { colId: 'a', colName: 'Date' },
  { colId: 'b', colName: 'Name of user' },
  { colId: 'c', colName: 'Action' },
  { colId: 'd', colName: 'Wording' },
  { colId: 'e', colName: 'Active' },
  { colId: 'f', colName: 'Archive' }
];

export const SP_FIELDS_LOGS_TABLE_COLUMNS: TableDataDTO[] = [
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
  {
    a: '02/03/2023 15:34:36',
    b: 'GALICHET Charlene',
    c: 'Modification de l\'item',
    d: 'Level d’appartenance',
    e: 'Non',
    f: 'Non',
  },
];

export interface PaginatedTableMetaData {
  count: number;
  total: number;
  pages: number;
  currentPage: number;
  prevPage: number | null;
  nextPage: number | null;
}

export interface EntityListDTO {
  data: EntityDTO[];
}

export interface EntityDTO {
    longName?: string,
    shortName?: string,
    id: number,
    ref: string
}

export interface searchSelectDTO {
  data: searchSelectOption[];
}

export interface searchSelectOption {
  name: string;
  ref: string;
  parentClientRef?: string | null;
}

export interface FormatValidationConfig {
  supportedFileTypes: string[];
  supportedFiles: FileExtensionAndSizeValidation[];
  errorFormatMessageLabel: string;
  errorSizeMessageLabel: string | null;
}

export interface FileExtensionAndSizeValidation {
  supportedFileExtensions: string[];
  maxFileSize: number | null; //measured in bytes
}

export const profilePictureValidationConfig: FormatValidationConfig = {
  supportedFileTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
  supportedFiles: [{ supportedFileExtensions: ['jpg', 'jpeg', 'png', 'gif'], maxFileSize: null }],
  errorFormatMessageLabel: 'INVALID_FORMAT_IMG',
  errorSizeMessageLabel: null
};

export enum UserSpaces {
  Operator = 'operator-area',
  Beneficiary = 'beneficiary-area',
  //psy, social worker, qwl officer
  Speaker = 'speaker-area',
  Dev = 'developer-area',
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum OperatorTypes {
  PSY = 'psychologist',
  SW = 'social_worker',
  QWL = 'qwl_officer'
}

export function createOnlyNumbersValidator(): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if(!value) {
      return null
    }
    const isNumeric = /^[0-9]+$/.test(value);
    return !isNumeric ? {isOnlyNumbers: true}: null;
  }
}


export function createWeakPasswordValidator(): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if(!value) {
      return null
    }
    const isNumeric = /^(?=.*[0-9]).{5,}$/.test(value);
    return !isNumeric ? {isWeakPassword: true}: null;
  }
}

export function createUserNoSpecialCharsValidator(): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if(!value) {
      return null
    }
    const isNumeric = /^[A-Za-z0-9\-_]+$/.test(value);
    return !isNumeric ? {isIlegalUser: true}: null;
  }
}

export enum Modes {
  Add = 'add',
  Edit = 'edit'
}

export enum CommonConstants {
  emailRequiredPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  passwordRequiredPattern = '^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*\\d){1,})(?=(.*\\W){1,}).{8,}$',
}

export interface UserDetailsResponseDTO {
	data: {
		language: LanguageOption,
		user: {
			role: string,
			firstName: string,
			lastName: string,
			email: string,
			phone: string | null,
			active: boolean,
			application: number | null,
			ref: string,
			connectionDate: string,
			connectionCount: number
		},
		applications: EntityDTO[],
		ref: string
	}
}

export interface LanguageOption {
  ref: string,
  name: string,
  code: string,
  locale: string
}
