import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-two-columns',
  templateUrl: './two-columns.component.html',
  styleUrls: ['./two-columns.component.scss']
})
export class TwoColumnsComponent {
  public userName: string;
  constructor(private translate: TranslateService, private sharedService: SharedService) {
    this.sharedService.currentLang.subscribe((lang: string) => {
      this.translate.setDefaultLang('en');
      this.translate.use(lang);
    })

    this.sharedService.userLocalStorage.subscribe(user => {
      const userData = this.sharedService.getLocalUserDetails()?.data?.user;

      if(userData) {
        this.userName =  `${userData.firstName} ${userData.lastName}`;
      }
    })
  }
}
