import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/shared.service';
import { UserDetailsResponseDTO, LanguageOption } from '../../interfaces/shared.interface';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [CommonModule, NgbNavModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('slideInOut', [
        state(
            'visible',
            style({
                height: '50px',
                opacity: '1',
                display: 'block'
            })
        ),
        state(
            'hidden',
            style({
                height: '0px',
                opacity: '0',
                display: 'none'
            })
        ),
        transition('visible => hidden', [animate('400ms ease-out')]),
        transition('hidden => visible', [animate('400ms ease-in')])
    ])
]
})
export class NavigationComponent {

  public languageOption: LanguageOption;
  public userName: string;
  public animationState: string = 'hidden';

  constructor(private sharedService: SharedService, private authService: MsalService) {
    this.sharedService.userLocalStorage.subscribe(user => {
      if(user){
        this.languageOption = (JSON.parse(user) as UserDetailsResponseDTO)?.data?.language;
        if(this.languageOption){
          this.sharedService.updateCurrentLang(this.languageOption.locale);
        }

        const userData = this.sharedService.getLocalUserDetails()?.data?.user;

        if(userData) {
          this.userName =  `${userData.firstName} ${userData.lastName}`;
        }
      }
    })
  }

  public langChangeHandler(evt: Event) {
    const currentLanguage = (evt.target as HTMLInputElement).value;
    this.sharedService.updateCurrentLang(currentLanguage);
  }

  public logout() {
    this.authService.logout();
    localStorage.removeItem('usr');
  }
}
