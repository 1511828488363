<div ngbAccordion [closeOthers]="true">
  <div
    ngbAccordionItem
    *ngFor="let panel of panels"
    [collapsed]="panel.name !== 'First'"
  >
    <h2 ngbAccordionHeader>
      <button *ngIf="!panel.link" ngbAccordionButton>
        <i class="fa fa-{{ panel.icon }}"></i>{{ panel.name | translate }}
      </button>
      <a *ngIf="panel.link" [routerLink]="[panel.link]">
        <button ngbAccordionButton class="no-open">
          <i class="fa fa-{{ panel.icon }}"></i>{{ panel.name | translate }}
        </button>
      </a>
    </h2>
    <div ngbAccordionCollapse>
      <div *ngIf="panel.subPanels" ngbAccordionBody>
        <ng-template>
          <ul>
            <li *ngFor="let subPanel of panel.subPanels">
              <a [routerLink]="[subPanel.link]" [queryParams]="{operatorType: subPanel.queryParams?.operatorType}">{{ subPanel.name | translate }}</a>
            </li>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>
</div>
