import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { combineLatest } from 'rxjs';

import {
  NgbAccordionConfig,
  NgbAccordionModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { SharedService } from 'src/app/shared/services/shared.service';
import { EntityListDTO, UserDetailsResponseDTO } from 'src/app/shared/interfaces/shared.interface';

import { panels } from './accordionMenu.config';

@Component({
  selector: 'app-accordion-menu',
  standalone: true,
  imports: [NgbAccordionModule, CommonModule, RouterModule, TranslateModule],
  templateUrl: './accordion-menu.component.html',
  styleUrls: ['./accordion-menu.component.scss'],
  providers: [NgbAccordionConfig],
})
export class AccordionMenuComponent {
  
  constructor(readonly config: NgbAccordionConfig, private sharedService: SharedService) {
    this.getUserDetails();
    this.sharedService.userDataUpdated.subscribe(item =>{
      if(item === true){
        this.getUserDetails();
      }
    });
    this.sharedService.userDataUpdated.next(false);
  }

  panels = [
    { name: 'Home', icon: 'home', link: '/' },
    {
      name: 'Customers',
      icon: 'users',
      subPanels: [
        { name: 'List of clients', link: '/clients' },
        { name: 'List of on-site locations', link: '/sitesdea' },
        { name: 'Customer contact list', link: '/clientcontacts' },
      ],
    },
    {
      name: 'Operators',
      icon: 'sign-language',
      subPanels: [
        { name: 'Psychologists', link: '/operators/psys' },
        { name: 'Social workers', link: '/ass' },
        { name: 'QWL Officers', link: '/caos' },
      ],
    },
    {
      name: 'PSY Interview Sheets',
      icon: 'address-book-o',
      subPanels: [
        { name: 'List of sheets', link: '/fichesentretien', queryParams: {operatorType: 'psychologist'} },
        {
          name: 'Sheets being deleted',
          link: '/fichesentretien/suppression', queryParams: {operatorType: 'psychologist'},
        },
        { name: 'Excel export of sheets', link: '/fichesentretien/export', queryParams: {operatorType: 'psychologist'} },
        {
          name: 'Beneficiary statistics',
          link: '/fichesentretien/statsbeneficiaires',
        },
        { name: 'Specific Interview sheet', link: '/fichesentretien/specific', queryParams: {operatorType: 'psychologist'} },
      ],
    },
    {
      name: 'SW interview sheets',
      icon: 'address-book-o',
      subPanels: [
        {
          name: 'List of beneficiaries',
          link: '/as-fichesentretien/beneficiaires',
        },
        {
          name: 'List of sheets',
          link: '/fichesentretien', queryParams: {operatorType: 'social_worker'},
        },
        {
          name: 'Sheets being deleted',
          link: '/fichesentretien/suppression', queryParams: {operatorType: 'social_worker'}
        },
        {
          name: 'Beneficiaries being deleted',
          link: '/as-fichesentretien/suppression-beneficiaires',
        },
        { name: 'Excel export of sheets', link: '/fichesentretien/export', queryParams: {operatorType: 'social_worker'} },
        { name: 'Specific Interview sheet', link: '/fichesentretien/specific', queryParams: {operatorType: 'social_worker'} },
      ],
    },
    {
      name: 'QWL Interview Sheets',
      icon: 'address-book-o',
      subPanels: [
        {
          name: 'List of beneficiaries',
          link: '/cao-fichesentretien/beneficiaires',
        },
        {
          name: 'List of sheets',
          link: '/fichesentretien',
          queryParams: {operatorType: 'qwl_officer'}
        },
        {
          name: 'Sheets being deleted',
          link: '/fichesentretien/suppression',
          queryParams: {operatorType: 'qwl_officer'}
        },
        {
          name: 'Beneficiaries being deleted',
          link: '/cao-fichesentretien/suppression-beneficiaires',
        },
        {
          name: 'Excel export of sheets',
          link: '/fichesentretien/export',
          queryParams: {operatorType: 'qwl_officer'}
        },
        { name: 'Specific Interview sheet', link: '/fichesentretien/specific', queryParams: {operatorType: 'qwl_officer'} },
      ],
    },
    {
      name: 'Data analysis',
      icon: 'database',
      subPanels: [
        {
          name: 'PSY customer statistics',
          link: '/fichesentretien/statsclient',
        },
        {
          name: 'AS customer statistics',
          link: '/as-fichesentretien/statsclient',
        },
        {
          name: 'QWL customer statistics',
          link: '/cao-fichesentretien/statsclient',
        },
        {
          name: 'MULTISERVICE customer statistics',
          link: '/main-fichesentretien/statsclient',
        },
      ],
    },
    {
      name: 'Practical advice',
      icon: 'file-text',
      link: '/documentation',
    },
    { name: 'Webinars', icon: 'calendar', link: '/webinaires' },
    { name: 'Self-assessment', icon: 'file-text', link: '/autoquest' },
    {
      name: 'Business developers',
      icon: 'address-card',
      link: '/businessdeveloper',
    },
    {
      name: 'Administrators',
      icon: 'user-circle',
      subPanels: [
        {
          name: 'List of administrators',
          link: '/administrators',
        }
      ],
    },
  ];

  public createPlanningEntriesInMenu(entityList:EntityListDTO, userDetails:UserDetailsResponseDTO):void{
    this.panels.forEach( (item) => { 
      if(userDetails?.data?.applications){
        if(item.name === 'Operators'){
          item.subPanels = item.subPanels?.filter((element) => element.name.indexOf('planning') === -1);

          for (const entity of entityList.data) {
            for (const application of userDetails.data.applications ) {
              if(application.id == entity.id){
                item.subPanels?.push({ name: `General planning ${entity.shortName?.replace('-', ' ')}`, link: `/operators/planning/${entity.shortName?.slice(4,6).toLowerCase()}` });
              }
            }
          }

        }
      } 
    });
  }

  private getUserDetails() {
    combineLatest([this.sharedService.getEntityList(), this.sharedService.getUserDetails()] ).subscribe(
      ([entityList, userDetails]) => {
        this.createPlanningEntriesInMenu(entityList, userDetails);

        const userData = JSON.stringify(userDetails);
        
        localStorage.setItem('usr', userData);
        this.sharedService.userIsInLocalStorage();
      }
    );
  }
}
