<div class="alert show alert-secondary fade">
  <ul ngbNav class="nav justify-content-end">
    <li class="nav-profile" (click)="animationState === 'visible' ? animationState = 'hidden' :animationState = 'visible'">
      <img src="/assets/images/stim-bo-avatar.jpg" alt="..." class="img-circle profile_img"/>
      <span>{{userName}}</span>
      </li>
      <li (click)="logout()" [@slideInOut]="animationState" class="logout">Log Out</li>
      <li>
      <select (change)="langChangeHandler($event)" class="form-select">
        <option value="en">English</option>
        <option *ngIf="languageOption" [selected]="true" [value]="languageOption.locale">{{languageOption.name}}</option>
      </select>
    </li>
  </ul>
</div>
