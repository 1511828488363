import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { searchSelectDTO } from '../../modules/interview-sheets/interfaces/specific-fields.interface';
import {EntityDTO, EntityListDTO, UserDetailsResponseDTO} from '../interfaces/shared.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private baseURL: string = environment.apiBaseUrl;
  public currentLang: BehaviorSubject<string> = new BehaviorSubject<string>('en');
  public userDataUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userLocalStorage: Subject<any> = new Subject<any>();

  constructor(private httpService: HttpClient) {}

  public getClientList(appId?: string): Observable<searchSelectDTO> {
    let headers = new HttpHeaders();
    if(appId){
      headers = headers.append('applicationId', appId);
    }

    return this.httpService.get<searchSelectDTO>(`${this.baseURL}/bo/clients/dropdown`, {headers});
  }

  public insertAtIndex(str: string, substring: string, index: number): string {
    const arr = str.split('');
    arr.splice(index, 0, substring);
    return arr.join('');
  }

  public formatDateForApi(date: string) {
    const dateArr = date.split('/');
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
  }

  public array_move(arr: any, old_index: number, new_index: number): any[] {
    const arrClone = [...arr];
    if (new_index >= arrClone.length) {
        let k = new_index - arrClone.length + 1;
        while (k--) {
          arrClone.push(undefined);
        }
    }
    arrClone.splice(new_index, 0, arrClone.splice(old_index, 1)[0]);
    return arrClone;
  }

  public formatManualInput(date: string): string {
    if (date && date.indexOf('/') === -1) {
      date = this.insertAtIndex(date, '/', 2);
      date = this.insertAtIndex(date, '/', 5);
    }
    return date;
  }

  public getEntityList(): Observable<EntityListDTO> {
    return this.httpService.get<EntityListDTO>(`${this.baseURL}/bo/applications`);
  }

  public getAdminEntityList(): EntityDTO[] {
    const user = this.getLocalUserDetails();
    return (user !== null) ? user.data.applications : [];
  }

  public updateCurrentLang(newLang: string): void {
    this.currentLang.next(newLang);
  }

  public getUserDetails(): Observable<UserDetailsResponseDTO> {
    return this.httpService.get<UserDetailsResponseDTO>(`${this.baseURL}/bo/administrators/me`);
  }

  public getLocalUserDetails(): UserDetailsResponseDTO | null  {
    const user = localStorage.getItem('usr');
    return (user !== null) ? (JSON.parse(user) as UserDetailsResponseDTO) : null;
  }

  public userIsInLocalStorage() {
    this.userLocalStorage.next(localStorage.getItem('usr'));
  }
}
