export const environment = {
  apiBaseUrl: 'https://dev-api.stimulus-care-services.com',
  production: false,
  msal: {
    signUpSignIn: 'B2C_1A_SM_signin_email_admin',
    resetPassword: 'B2C_1A_SM_PasswordReset',
    editProfile: 'B2C_1_ProfileEditing_1',
    authorities: {
      signUpSignIn: {
        authority: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1A_SM_signin_email_admin',
      },
      resetPassword: {
        authority: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1A_SM_PasswordReset',
      },
      editProfile: {
        authority: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1_ProfileEditing_1',
      },
    },
    authorityDomain: 'scsb2cpoc.b2clogin.com',
    b2cScopes: [
      "https://scsb2cpoc.onmicrosoft.com/tasks-api/app.read",
      "https://scsb2cpoc.onmicrosoft.com/tasks-api/app.write",
    ],
    clientId: 'b3002b99-8afe-42ae-b8ca-51e910d187a6'
  }
};
