import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TwoColumnsComponent } from './layout/two-columns/two-columns.component';
import { OneColumnComponent } from './layout/one-column/one-column.component';
import { LoginComponent } from './modules/auth/login/login.component';
import {MsalGuard} from "@azure/msal-angular";

const appRoutes: Routes = [
  {
    path: '',
    component: TwoColumnsComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
            canActivate: [MsalGuard]
          },
        ],
      },
      {
        path: '',
        children: [
          {
            path: 'fichesentretien',
            loadChildren: () => import('./modules/interview-sheets/interview-sheets.module').then(m => m.InterviewSheetsModule),
            canActivateChild: [MsalGuard]
          },
        ],
      },
      {
        path: '',
        children: [
          {
            path: 'clients',
            loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule),
            canActivateChild: [MsalGuard]
          },
        ],
      },
      {
        path: '',
        children: [
          {
            path: 'operators',
            loadChildren: () => import('./modules/operators/operators.module').then(m => m.OperatorsModule),
            canActivateChild: [MsalGuard]
          },
        ],
      },
      {
        path: '',
        children: [
          {
            path: 'administrators',
            loadChildren: () => import('./modules/administrators/administrators.module').then(m => m.AdministratorsModule),
            canActivateChild: [MsalGuard]
          },
        ],
      }
    ],
  },
  {
    path: '',
    component: OneColumnComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: 'login',
            component: LoginComponent
          },
        ],
      }
    ],
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
