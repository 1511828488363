import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {map, Observable, catchError, mergeMap, throwError} from 'rxjs';
// import {TranslateService} from "@ngx-translate/core";
import {MsalService} from "@azure/msal-angular";
import {msalConfig} from "./auth-config";
import {environment} from "../environments/environment";

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  constructor(private readonly injector: Injector, private msalService: MsalService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let account = this.msalService.instance.getActiveAccount();
    if (account) {
      //TODO the MSALInterceptorConfigFactory from app.module does not seem to want to put the idToke, it sets an empty
      // bearer. I saw some discussions on their gitHub page but it seems closed from 2022
      // link here: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4716
      // here should be a working sample https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v3-samples/angular15-sample-app/package.json
      const idTokenLocalStorageKey = `${account.homeAccountId}-${environment.msal.authorityDomain}-idtoken-${msalConfig.auth.clientId}----`
      const localData = localStorage.getItem(idTokenLocalStorageKey) || '';
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${JSON.parse(localData).secret}`)
      })
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.status === 403) {
          this.msalService.logout();
          this.msalService.loginRedirect();
          // localStorage.removeItem('userRole')
        }
        if (error.status === 401) {
          return this.msalService.acquireTokenSilent({
            scopes: [],
          }).pipe(
            mergeMap((token) => {
              console.log(token)
              request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token.idToken}`)
              })
              return next.handle(request);
            }),
            catchError((error) => {
              console.error('Error acquiring token:', error);
              return throwError(error);
            })
          );
        } else {
          console.error('Error:', error);
          return throwError(error);
        }
      })
    ) as Observable<HttpEvent<any>>;
  }

  // private getCountry(headers: HttpHeaders) {
  //   const countryHeader = headers.get('Country');
  //   const translateService = this.injector.get(TranslateService)

  //   if (countryHeader !== null) {
  //     translateService.addLangs([countryHeader.toLowerCase()])
  //     translateService.setDefaultLang(countryHeader.toLowerCase())
  //     localStorage.setItem('lang', countryHeader.toLowerCase())
  //   }
  // }
}

