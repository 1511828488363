<div class="container-fluid">
  <div class="row min-vh-100">
    <div class="col-2 bo-main-left">
      <div class="row">
        <div class="col bo-logo-wrapper ps-4">
          <i class="fa fa-universal-access fs-22"></i>
          <span class="fs-22">STIMULUS</span>
        </div>
      </div>
      <div class="row">
        <div class="col ps-4">
          <div class="row profile mb-4">
            <div class="col-sm-5 col-lg-4 profile_img_wrapper">
              <img
                src="/assets/images/stim-bo-avatar.jpg"
                alt="..."
                class="img-circle profile_img"
              />
            </div>
            <div class="col profile_info">
              <span class="bo-welcome-text">{{"Welcome" | translate}},</span>
              <div class="bo-username">{{ userName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-accordion-menu></app-accordion-menu>
        </div>
      </div>
    </div>
    <div class="col-10 bo-main-right">
      <app-navigation></app-navigation>
      <div class="bo-content container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

